import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DatePicker from "react-datepicker";
import "../../node_modules/react-datepicker/dist/react-datepicker.css";
import $ from "jquery"
import CustomCalendar from "../components/customcalendar"
import EventsSlider from "../components/eventsslider"
import AlertsSlider from "../components/alertsslider"
import SEO from "../components/seo"

class PageTemplate extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.Changedate = this.Changedate.bind(this)
    this.state = {
      departmentsId : [],
      news: [],
      AllSingleNews: ``,
      date: null,
      endDate: null,
      firstTime: 0,
      currentDate: ``
    }
  }


  Changedate = (e) => {
    this.setState({
      date: e,
      departmentsId : this.state.departmentsId,
    });
  };

  ChangeEndDate = (e) => {
    this.setState({
      endDate: e,
      departmentsId : this.state.departmentsId,
    });
  };

  handleSubmit = event => {
    event.preventDefault()
    var moment = require('moment'); // require
    var allNews = this.state.AllSingleNews
    var allDepartmentsIds = this.state.departmentsId
    var tempGroup
    var filteredNews = []
      if(this.state.date !== null && this.state.endDate !== null) {
        var startDateFormat = moment(this.state.date).format("YYYY-MM-DD");
        var endDateFormat = moment(this.state.endDate).format("YYYY-MM-DD");
        filteredNews = allNews.filter(item => {
          allDepartmentsIds.includes('all') ? (
            startDateFormat <= moment(item.node.field_start_date).format("YYYY-MM-DD") && moment(item.node.field_start_date).format("YYYY-MM-DD") <= endDateFormat ? (
              tempGroup = true
            ) : (
              tempGroup = false
            )
          ) : (
            startDateFormat <= moment(item.node.field_start_date).format("YYYY-MM-DD") && moment(item.node.field_start_date).format("YYYY-MM-DD") <= endDateFormat ? (
              item.node.relationships.group_content__departments_group_node_event !== null ? (
                item.node.relationships.group_content__departments_group_node_event.map((gr,key) => (
                  allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                    tempGroup = true
                  ) : (
                    tempGroup = false
                  )
                ))
              ) : (
                tempGroup = false
              )
            ) : (
              tempGroup = false
            )
          )
          return tempGroup
        })
      } else if(this.state.date !== null ) {
        var startDateFormat = moment(this.state.date).format("YYYY-MM-DD");
        filteredNews = allNews.filter(item => {
          allDepartmentsIds.includes('all') ? (
            startDateFormat <= moment(item.node.field_start_date).format("YYYY-MM-DD") ? (
              tempGroup = true
            ) : (
              tempGroup = false
            )
          ) : (
            startDateFormat <= moment(item.node.field_start_date).format("YYYY-MM-DD") ? (
              item.node.relationships.group_content__departments_group_node_event !== null ? (
                item.node.relationships.group_content__departments_group_node_event.map((gr,key) => (
                  allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                    tempGroup = true
                  ) : (
                    tempGroup = false
                  )
                ))
              ) : (
                tempGroup = false
              )
            ) : (
              tempGroup = false
            )
          )
          return tempGroup
        })
      } else if(this.state.endDate !== null) {
        var endDateFormat = moment(this.state.endDate).format("YYYY-MM-DD");
        filteredNews = allNews.filter(item => {
          allDepartmentsIds.includes('all') ? (
            moment(item.node.field_start_date).format("YYYY-MM-DD") <= endDateFormat ? (
              tempGroup = true
            ) : (
              tempGroup = false
            )
          ) : (
            moment(item.node.field_start_date).format("YYYY-MM-DD") <= endDateFormat ? (
              item.node.relationships.group_content__departments_group_node_event !== null ? (
                item.node.relationships.group_content__departments_group_node_event.map((gr,key) => (
                  allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                    tempGroup = true
                  ) : (
                    tempGroup = false
                  )
                ))
              ) : (
                tempGroup = false
              )
            ) : (
              tempGroup = false
            )
          )
          return tempGroup
        })
      } else {
        filteredNews = allNews.filter(item => {
          allDepartmentsIds.includes('all') ? (
            tempGroup = true
          ) : (
            item.node.relationships.group_content__departments_group_node_event !== null ? (
              item.node.relationships.group_content__departments_group_node_event.map((gr,key) => (
                allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                  tempGroup = true
                ) : (
                  tempGroup = false
                )
              ))
            ) : (
              tempGroup = false
            )
          )
          return tempGroup
        })
     }

      this.setState({
        news: filteredNews,
        AllSingleNews: this.state.AllSingleNews,
        departmentsId : this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: 1
      });
   }
   handleChange = event => {
    if(event.target.checked) {
      this.state = {
        news: this.state.news,
        AllSingleNews: this.state.AllSingleNews,
        departmentsId: this.state.departmentsId.concat(event.target.value),
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: 1
      }
    } else {
      var array = this.state.departmentsId
      var index = array.indexOf(event.target.value)
      if (index !== -1) {
          array.splice(index, 1);
          this.state = {
            news: this.state.news,
            AllSingleNews: this.state.AllSingleNews,
            departmentsId : array,
            date: this.state.date,
            endDate: this.state.endDate,
            firstTime: 1
          }
      }
    }

  }

  componentDidMount() {
    this.UIkit = require('uikit/dist/js/uikit');
    var es = this;
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    $('.error-message').addClass('uk-hidden');

    if(getUrlParameter('guid')) {
      setTimeout(function(){
        $('.select-all').hide()
        $('label[class^="label"]').hide();
        $(".label"+getUrlParameter('guid')).show().click()
        $(".confirm-button").click()
      },100)
      setTimeout(function(){
        $(".confirm-button").click()
      },200)
    }
    if($('.events-filters').length > 0) {
      setTimeout(function(){
        $('.select-all').click();
        es.setState( () => {
          return {
            news: es.state.AllSingleNews,
            AllSingleNews: es.state.AllSingleNews,
            departmentsId : ['all'],
            date: null,
            endDate: null,
            firstTime: 1
          }
        });
      }, 100);
    }

    $('.events-filters .clear-all').click(function(e) {
      e.preventDefault();
      $('.events-filters .uk-grid .label').each(function() {
        if($(this).find('input')[0].checked == true) {
          $(this).click();
        }
      });
      if($('.select-all input')[0].checked == false) {
        $('.select-all').click();
      }

      $('.date-range input').each(function() {
        $(this).val(null);
      });

      es.setState( () => {
        return {
          news: es.state.AllSingleNews,
          AllSingleNews: es.state.AllSingleNews,
          departmentsId : ['all'],
          date: null,
          endDate: null,
          firstTime: 1
        }
      });
    });

    $('body').on('click','.rbc-show-more, .rbc-month-row .rbc-date-cell > a',function(){
      $('.rbc-calendar .rbc-toolbar .rbc-btn-group:last-of-type button:first-child').text('Back to Month View').show();
      setTimeout(function(){
        $('.mounth-label').text( $('.rbc-toolbar-label').text())
      },100)
    })
    $('body').on('click','.rbc-calendar .rbc-toolbar .rbc-btn-group:last-of-type button',function(){
      $(this).hide()
      setTimeout(function(){
        $('.mounth-label').text( $('.rbc-toolbar-label').text())
      },100)
    })

    $('.events-filters .uk-grid .label').click(function() {
      if($('.select-all input')[0].checked ) {
        $('.select-all').click();
      }
    });

    $('.filter-dropdown').click(function() {
      $('.overlay-filter').addClass('active-overlay');
    });

    $('.events-filters .confirm-button').click(function() {
      $('.overlay-filter').removeClass('active-overlay');
    });
    $('.overlay-filter').click(function() {
      $('.overlay-filter').removeClass('active-overlay');
      $('#toggle-usage').prop('hidden', true);
      $('.filter-dropdown')[0].ariaExpanded = 'false';
    });

    function getCurrentDate() {
      const d = new Date();
      let month = (d.getMonth() + 1).toString();
      if (month.length < 2) {
        month = `0${month}`;
      }
      let day = d.getDate().toString();
      if (day.length < 2) {
        day = `0${day}`;
      }
      return `${d.getFullYear()}-${month}-${day}`;
    }

    es.setState( () => {
      return {
        currentDate: getCurrentDate()
      }
    });




  }


  render() {
    var moment = require('moment');
    const currentPage = this.props.data.nodePage
    var pastEvents
    const alerts = this.props.data.allNodeAlert
    const allDepartments = this.props.data.allGroupDepartments
    const meta = currentPage.relationships.field_meta_data
    const allLandingPages = this.props.data.allNodeLandingPage.edges

    var landingPageRel = []
    var landningGroup
    allLandingPages && allLandingPages.map((page,key) => (
      page.node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
        landningGroup = page.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id,
        landingPageRel[landningGroup] = [],
        landingPageRel[landningGroup]['title'] = page.node.relationships.field_hero.field_title_html.value,
        landingPageRel[landningGroup]['path'] = page.node.path.alias

      ) : (
        null
      )))

    const filterAllDepartments = allLandingPages.filter(
      item => item.node.relationships.group_content__group_content_type_20b7e70e3cc2b && ( item.node.field_include_in_list || item.node.relationships.field_parent_agency === null)
    )
    var allEventsTempGlobal = this.props.data.allNodeEvent.edges
    var allEventsTemp = this.props.data.allNodeEvent.edges.filter(item => (item.node.relationships.group_content__departments_group_node_event !== null))
    this.state.firstTime === 0 || this.state.departmentsId.includes('all') ? (
      this.state = {
        AllSingleNews: allEventsTempGlobal,
        news: this.state.news,
        departmentsId: this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: this.state.firstTime,
        currentDate: this.state.currentDate
      }
    ) : (
      this.state = {
        AllSingleNews: allEventsTempGlobal,
        news: this.state.news,
        departmentsId: this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: this.state.firstTime,
        currentDate: this.state.currentDate
      }
    )

    this.state = {
      AllSingleNews: allEventsTempGlobal,
      news: this.state.news,
      departmentsId: this.state.departmentsId,
      date: this.state.date,
      endDate: this.state.endDate,
      firstTime: this.state.firstTime,
      currentDate: this.state.currentDate
    }
    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={meta}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />
      <div className="overlay-filter"></div>
        <div className="site-content events uk-padding-remove-top">
          <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />
          <div className="container uk-margin-medium-top">

            <div className="title-filters uk-flex-middle uk-padding-remove-top">
              <h1>Events</h1>
              <div className="button-wrp">
                <a className="uk-button filter-dropdown uk-margin-remove-bottom" type="button" data-uk-toggle="target: #toggle-usage" data-aria-expanded="true">Filters</a>
              </div>
            </div>

            <div className="toggle" hidden id="toggle-usage">
            <div className="close-event-filter"></div>
              <form className="events-filters" onSubmit={this.handleSubmit}>

                <h2 className="h3">Departments</h2>

                <div>
                  <label className="select-all">
                      <input type="checkbox" name="events-all-departments" id="events-all-departments" onChange={this.handleChange} value='all' />
                      All Departments
                  </label>
                </div>

                <div className="uk-grid uk-child-width-1-4@m" data-uk-grid>
                  <div>

                  {filterAllDepartments && filterAllDepartments.map((department,key) => (
                    key < 10 ? (
                      <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                        <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                        <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                      </label>
                    ) : (
                      null
                    )
                  ))}
                  </div>
                  <div>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                      key >= 10 && key < 20 ? (
                        <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                          <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                          <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                        </label>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                  <div>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                      key >= 20 && key < 30 ? (
                        <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                          <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                          <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                        </label>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                  <div>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                       key >= 30 ? (
                        <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                          <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                          <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                        </label>
                      ) : (
                        null
                      )
                    ))}

                  </div>
                </div>

                <div className="uk-grid uk-child-width-1-2 date-range-confirm uk-flex-middle" data-uk-grid>
                  <div className="date-range">
                    <h2 className="h3">Date Range</h2>

                    <DatePicker className="form-control" id="date"
                        selected={this.state.date} placeholderText="Start Date" showPopperArrow={false}
                        onChange={this.Changedate} autoComplete="off"
                      />

                      <DatePicker className="form-control" id="today"
                        selected={this.state.endDate} placeholderText="End Date" showPopperArrow={false}
                        onChange={this.ChangeEndDate} autoComplete="off"
                      />

                  </div>
                  <div className="clear-confirm">
                    <input href="#" className="clear-all" type="reset" value="Clear All"/>
                    <input className="confirm-button" data-uk-toggle="target: #toggle-usage" type="submit" value="Confirm" />
                  </div>
                </div>


              </form>
            </div>


              <div id="events-slider" className="uk-position-relative uk-visible-toggle uk-slider" tabIndex="-1" data-uk-slider >

                {
                  pastEvents = this.state.news.filter(item => item.node.field_start_date >= this.state.currentDate),
                  pastEvents.length > 0 ? (
                  <EventsSlider events={pastEvents} landingPages={landingPageRel} />
                ) : (
                  <p className="error-message">
                    We found no results. Please clear the filter and start over.
                  </p>
                )}

              </div>

              {this.state.news.length > 0 ? (
                <CustomCalendar eventsRaw={this.state.news} />
              ) : (
                null
              )}

          </div> {/*container*/}
        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $date: Date!) {
    nodePage(id: { eq: $id }) {
      title
      path {
        alias
      }
      body {
        value
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
      }
    }
    allGroupDepartments(sort: {fields: label}) {
      edges {
        node {
          label
          drupal_internal__id
        }
      }
    }

    allNodeAlert(filter: {field_global_post: {eq: true}},sort: {fields: field_weight, order: ASC}) {
      edges {
        node {
          title
          field_weight
          field_alert_text
          field_link {
            title
            uri
          }
          field_alert_type
          field_global_post
          relationships {
            group_content__departments_group_node_alert {
              relationships {
                gid {
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allNodeLandingPage(sort: {fields: relationships___field_hero___field_title_html___value}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }

    allNodeEvent(sort: {fields: field_start_date, order: ASC}, filter: { field_end_date: { gte: $date } }) {
      edges {
        node {
          title
          body {
            value
          }
          created
          field_end_date
          field_start_date
          field_address_line_1
          field_address_line_2
          field_virtual_event
          path {
            alias
          }
          relationships {
            group_content__departments_group_node_event {
              relationships {
                gid {
                  drupal_internal__id
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`
